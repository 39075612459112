import React from 'react';
import './Header.css'; // Import the CSS file for styles
import logo from "../assets/airbooked.png";
import { CiPhone } from "react-icons/ci";

const Header = () => {
  const handleButtonClick = () => {
    const isMobile = window.innerWidth <= 639; // Mobile breakpoint
    const mobileNumber = "0433310964";

    if (isMobile) {
      // Directly call the number on mobile
      window.location.href = `tel:${mobileNumber}`;
    } else {
      // Show the number in an alert for desktop
      alert(`Call us at: ${mobileNumber}`);
    }
  };

  return (
    <>
      <header className="header">
        <img className="logo" src={logo} alt="logo" />
        <button className="book-chat" onClick={handleButtonClick}>
          <CiPhone size={30} /> CALL NOW
        </button>
      </header>
    </>
  );
};

export default Header;




